import './index.less';

import Vue from 'vue';
import Component from 'vue-class-component';
import { ChildDetailToThis, ChildDetailToNotThis } from '@/app/components';
import { ApproveRecord, RegRecordPage, zsRegRecords } from '@/app/apiServices/common/dtos/common';
import { getPdf } from '@/core/utils/htmlToPdf'
import { noOpen, noRegister, registerEnd, requestError } from '@/config/message';
import commonService from '@/app/apiServices/common/commonService';
import zsChildBaseInfoService from '@/app/apiServices/zsChildBaseInfo/zsChildBaseInfoService';
import { UploadEvidenceChildDto } from '@/app/apiServices/baseDto/storeDto';
import storage from '@/core/utils/storage';
import { isNullOrEmpty } from '@/core/utils';

@Component({
    components: { ChildDetailToThis, ChildDetailToNotThis }
})
export default class recordTS extends Vue {
    active = 0;
    regRecord = new RegRecordPage();
    dialogVisible = false;
    dialogChild = {
        childId: 0,
        childType: 0
    };
    // dialogChildData = new SaveRefChildGardenDto();
    isOpen = false; //需线下验证
    isOpen2 = false; //需重新提交相关材料
    currentRecord = new zsRegRecords();
    approveRecord = new ApproveRecord();

    //是否开放招生
    isZSOpen = false;
    message = ""; //提示语

    getPdf() {
        getPdf("黄浦区适龄幼儿入园报名信息");   //下载文件名
    }

    //获取状态样式
    getClass(result: string) {
        switch (result) {
            case "草稿":
                return "bg-draft";
            case "已报名":
            case "验证中":
                return "bg-wait";
            case "验证通过":
                return "bg-success";
            default:
                return "bg-fail";
        }
    }

    //获取状态样式
    getResultClass(zsRegRecords: Array<zsRegRecords>) {
        //验证阶段统一显示验证中
        if (this.regRecord.enrollPeriod == 1) {
            return this.getClass("验证中");
        }

        let result = "";
        if (zsRegRecords.length > 1) {
            zsRegRecords.forEach((item: zsRegRecords) => {
                if (isNullOrEmpty(result)) {
                    result = item.checkResult;
                }
                if (!isNullOrEmpty(result) && result != item.checkResult) {
                    result = "验证中";
                }
            })
        }
        else {
            result = zsRegRecords[0].checkResult;
        }
        return this.getClass(result);
    }

    //获取状态显示
    getResultText(zsRegRecords: Array<zsRegRecords>) {
        //验证阶段统一显示验证中
        if (this.regRecord.enrollPeriod == 1) {
            return "验证中";
        }

        let result = "";
        if (zsRegRecords.length > 1) {
            zsRegRecords.forEach((item: zsRegRecords) => {
                if (isNullOrEmpty(result)) {
                    result = item.checkResult;
                }
                else {
                    if (!isNullOrEmpty(result) && result != item.checkResult) {
                        result = "验证中";
                    }
                }
            })
        }
        else {
            result = zsRegRecords[0].checkResult;
        }

        return (result == "需重新提交相关材料" || result == "需进行线下验证") ? "验证不通过" : result;
    }

    closeDialog() {
        this.dialogVisible = false;
    }

    toRigist(childId: any) {
        if (!this.isZSOpen) {
            this.$dialog.alert({
                message: this.message,
            });
            return;
        }
        // this.$router.push({ path: "entrance", query: { childId: childId } });
        this.$store.dispatch("ACTION_ActiveChildId", childId);
        this.$router.push("/entrance");
    }
    add() {
        if (!this.isZSOpen) {
            this.$dialog.alert({
                message: this.message,
            });
            return;
        }

        storage.DELETE_ActiveChildId();
        this.$router.push("/entrance");
    }
    deleteItem(childId: number) {
        this.$dialog.confirm({
            title: "提示",
            confirmButtonText: "确定",
            message: "是否确认删除当前幼儿报名记录?"
        }).then(() => {
            zsChildBaseInfoService.deleteZSChildBaseInfo(childId).then(() => {
                this.$toast.success("删除成功！");
                this.viewLoad();
                return true;
            }).catch(() => {
                this.$toast({ message: requestError, duration: 3000 });
                return false;
            });

        }).catch(() => {
            //close
        });
    }

    //查看详情
    viewDetails(item: any) {
        this.dialogChild.childId = item.childId;
        this.dialogChild.childType = item.childType;
        this.dialogVisible = true;
    }

    //提示去查看打分对比
    showTips() {
        this.$dialog
            .alert({
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "关闭",
                messageAlign: "left",
                message:
                    "信息验证仅审核报名材料。父母一方居住证积分未达到标准分值的非本市户籍小班适龄幼儿，按照“黄浦区非沪籍人士子女申请就读幼儿园打分表”持证打分，视学额和打分情况依次逐步安排。外籍、港澳台幼儿参照执行。",
            })
            .then(() => {
                // on close
            });
    }

    //查看原因
    showReason(val: zsRegRecords) {
        this.currentRecord = val;
        this.approveRecord = val.approveRecord;
        if (this.approveRecord.reason == "需重新提交相关材料") {
            this.isOpen2 = true;
        } else {
            this.isOpen = true;
        }
    }

    //前往佐证
    toUploadEvidence(val: zsRegRecords) {
        if (val.hasSupplied) {
            this.$toast({
                message: "您已经提交过补充材料",
                position: "top",
            });
        } else {
            const data: UploadEvidenceChildDto = {
                childId: Number(val.childId),
                enterGardenType: Number(val.enterGardenType),
                gradeCode: val.gradeCode,
                gardenId: val.gardenId,
                refId: Number(val.refId)
            }
            //需要重新打分
            if (val.needPointTable) {
                this.$store.dispatch("ACTION_UploadEvidenceChild", data);
                this.$router.push("/parentScore");
            } else if (!val.hasSupplied) {
                this.$store.dispatch("ACTION_UploadEvidenceChild", data);
                this.$router.push("/uploadEvidence");
            }
        }
    }

    // //前往佐证
    // toUploadEvidence() {
    //     if (this.currentRecord.hasSupplied) {

    //         //关闭原有弹框
    //         this.isOpen2 = false;

    //         this.$toast({
    //             message: "您已经提交过补充材料",
    //             position: "top",
    //         });
    //     } else {
    //         const data: UploadEvidenceChildDto = {
    //             childId: Number(this.currentRecord.childId),
    //             enterGardenType: Number(this.currentRecord.enterGardenType),
    //             gradeCode: this.currentRecord.gradeCode,
    //             gardenId: this.currentRecord.gardenId,
    //             refId: Number(this.currentRecord.refId)
    //         }
    //         //需要重新打分
    //         if (this.currentRecord.needPointTable) {
    //             this.$store.dispatch("ACTION_UploadEvidenceChild", data);
    //             this.$router.push("/parentScore");
    //         } else if (!this.currentRecord.hasSupplied) {
    //             this.$store.dispatch("ACTION_UploadEvidenceChild", data);
    //             this.$router.push("/uploadEvidence");
    //         }
    //     }
    // }

    checkOpen() {
        //获取是否开放招生
        commonService.checkZSOpen().then((res: any) => {
            switch (res) {
                case 0:
                    this.message = noOpen;
                    break;
                case 2:
                    this.message = noRegister;
                    break;
                case 3:
                    this.message = registerEnd;
                    break;
                default:
                    break;
            }
            this.isZSOpen = this.message == "";
        });
    }

    async viewLoad() {
        const { data }: any = this.$store.getters.GET_CurrentUser;
        // const account = data ? data.idcard : "";
        const account = process.env.VUE_APP_ENV != "DEV" ? (data ? data.idcard : "") : "342623199812082530"  //342623199812082530
        commonService.getRegRecordPage(account).then((res: RegRecordPage) => {
            this.regRecord = Object.assign(this.regRecord, res);
        });
    }

    mounted(): void {
        this.viewLoad();
        this.checkOpen();
    }
}