export interface GetBabyInfoInput {
    id: string;
    name: string;
    cardNumber: string;
}

//各个年级招生范围
export interface IZSDateRange {
    tB_StartDate: Date;
    tB_EndDate: Date;
    xB_StartDate: Date;
    xB_EndDate: Date;
    zB_StartDate: Date;
    zB_EndDate: Date;
    dB_StartDate: Date;
    dB_EndDate: Date;
}

//各个年级招生范围
export class ZSDateRange implements IZSDateRange {
    tB_StartDate = new Date;
    tB_EndDate = new Date;
    xB_StartDate = new Date;
    xB_EndDate = new Date;
    zB_StartDate = new Date;
    zB_EndDate = new Date;
    dB_StartDate = new Date;
    dB_EndDate = new Date;
}

//报名记录
export class RegRecordPage {
    enrollPeriod = 0;
    showRepRecord = false;  //是否显示录取信息
    zsRegChildren: Array<zsRegChildren> = [];   //报名记录
    zsRepRecords: Array<ZSRepRecords> = [];  //录取记录
}

//报名记录幼儿信息
export class zsRegChildren {
    childId = "";
    childName = "";
    gB_SFZ = "";
    gB_ZJLX = "";
    regNumber = "";
    grade = "";
    isFinish = false;
    zsRegRecords: Array<zsRegRecords> = [];
}

//报名记录幼儿报名信息
export class zsRegRecords {
    gB_SFZ = "";
    childId = "";
    childName = "";
    enterGardenType = null;
    grade = "";
    gradeCode = "";
    gardenId = 0;
    gardenName = "";
    address = "";
    ownerShip = "";
    //填报时间
    opTime = "";
    //市登记编号
    regNumber = "";
    //审核结果
    checkResult = "";
    //预约时间
    orderTime = null;
    //关联的refchildGardenId
    refId = "";
    //是否已将上传补充材料
    hasSupplied = "";
    //是否需要重新打分
    needPointTable = "";
    //是否显示打分表比对
    showTableCompare = "";
    //审批不通过原因
    approveRecord = new ApproveRecord();
}

//幼儿录取记录
export class ZSRepRecords {
    childName = "";
    childId = "";
    gardenName = "";
    gardenId = "";
}

//审批不通过原因
export class ApproveRecord {
    //不通过原因
    reason = "";
    //详细原因
    detialReason = "";
    // 二级详细原因
    secondDetialReason = "";
    // 验证日期
    checkDate = "";
    // 验证时间
    checkTime = "";
    // 验证地点
    checkAddress = "";
}