// 导出页面为PDF格式
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'

const a4width = 592.28; // A4的宽度，以毫米为单位
const a4Height = 841.89; // A4的高度，以毫米为单位

export async function getPdf(title: string) {
    // const pdfDom: any = document.querySelector('#pdfDom');
    const itemDom: any = document.querySelectorAll('.pdf-item');
    const PDF = new JsPDF(undefined, 'pt', 'a4');

    let position = 24;    //图像的纵坐标，即左上角的y坐标
    let pageItemHight = 0;   //页面中item的高度

    const imgData = await getImages(itemDom);
    imgData.forEach((itemCanvas: any) => {
        const contentWidth = itemCanvas.width;
        const contentHeight = itemCanvas.height;
        //一页pdf显示html页面生成的canvas高度
        // const pageHeight = contentWidth / a4width * a4Height - 64;
        const pageHeight = PDF.internal.pageSize.height;

        const imgWidth = a4width;
        const imgHeight = a4width / contentWidth * contentHeight;
        const itemPageData = itemCanvas.toDataURL('image/jpeg', 1.0);

        //计算页面画面高度
        pageItemHight += imgHeight;

        //如果加上当前item已超过当前页面高度，则另开页面
        if ((pageItemHight + 48) > pageHeight) {
            PDF.addPage();    //添加新页面
            pageItemHight = 0;  //重置页面中item的高度
            position = 24;   //重置x坐标起始位置
        }

        //将图像添加到PDF文档中的函数(图像的URL或base64编码的数据,指定图像的格式,图像的横坐标，图像的纵坐标，图像的宽度，图像的高度)
        PDF.addImage(itemPageData, 'JPEG', 24, position, imgWidth - 48, imgHeight);
        position += imgHeight;   //图像的纵坐标，即左上角的y坐标
    });
    PDF.save(title + '.pdf');
}

function getImages(itemDom: any) {
    const promises: any[] = [];
    itemDom.forEach((item: any) => {
        const promise = html2Canvas(item, { allowTaint: true }).then(function (canvas) {
            if (canvas.height > 0) {
                return canvas; // 返回Promise对象，以便集中处理
            }
        });
        promises.push(promise);
    });
    return Promise.all(promises); // 返回一个新的Promise对象
}
